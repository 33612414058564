@tailwind base;
@tailwind components;
@tailwind utilities;

/* --------------------- */
/* -------GENERAL------- */
/* --------------------- */

*, button{
    cursor: url("../assets/icons/cursor-light.png") 17 17, auto;
}

.dark *, .dark button{
    cursor: url("../assets/icons/cursor-dark.png") 17 17, auto;
}

html {
    overflow: auto;
    overscroll-behavior: none;
    touch-action: pan-x pan-y;
    scroll-snap-type: y proximity;
    scrollbar-width: none;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Chakra Petch', sans-serif;
    user-select: none;
}

.main-container {
    width: 100%;
    min-width: 320px;
    box-sizing: border-box; 
    padding: 0;
    margin: 0;
}
  
h1, h2, h3, h4, h5, h6 {
    font-family: 'Orbitron', sans-serif;
}

h1 {
    font-size: 2.875rem;
}

h2{
    font-size: 2rem;
}

h3{
    font-size: 1.75rem;
}

h4 {
    font-size: 1.25rem;
}

p {
    font-size: 1rem;
}

.mw-auto {
    width: 100%;
    max-width: 1920px;
    margin: auto;
}

img {
    image-rendering: -webkit-optimize-contrast; 
  }

/*Color Theme Transition*/ 

.transition-hover-light, .transition-hover-dark, .transition-hover-svg-light, .transition-hover-svg-dark {
    transition: color 0.3s ease-out, stroke 0.3s ease-out, fill 0.3s ease-out;
}

.transition-hover-light:hover {
    color: #E3170A;
}

.transition-hover-dark:hover {
    color: #CFD11A;
}

.transition-hover-svg-light:hover {
    stroke: #E3170A;
    fill: #E3170A
}

.transition-hover-svg-dark:hover {
    stroke: #CFD11A;
    fill: #CFD11A
}

.opa-0 {
    transition: opacity 0.3s ease-out;
    opacity: 0%;
}

.opa-100 {
    transition: opacity 0.3s ease-out;
    opacity: 100%;
}

/* --------------------- */
/* -------SECTION------- */
/* --------------------- */

/* Landing */ 

.container-landing {
    align-items: space-around;
}

.container-title {
    width: 450px;
}

.custom-border-box {
    position: relative;
    width: 100%;
    height: 100%;
}

.custom-border-box::before,
.custom-border-box::after {
    content: '';
    position: absolute;
    width: 30%;
    height: 30%;
    border-width: 1px;
    border-style: solid;
}

/* Technologies */

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade-in {
    opacity: 0;
    animation: fade-in 0.6s ease-in-out forwards;
  }

#title-front-end {
    animation-delay: 0.1s;
}

#vector-front-end {
    animation-delay: 0.3s;
}

#vector-back-end {
    animation-delay: 2.1s;
}

#title-back-end {
    animation-delay: 2.3s;
}

@keyframes grow-in {
    0% {
        transform: scale(1);
        filter: grayscale(100%);
    }
    50% {
        transform: scale(1.1);
        filter: grayscale(0);
    }
    100% {
        transform: scale(1);
        filter: grayscale(100%);
    }
}

.grow-in .logo-techno {
    transform: scale(1.1);
    filter: grayscale(0);
}

.logo-techno  {
    transition: transform 0.4s ease-in-out, filter 0.4s ease-in-out;
    filter: grayscale(100%);
    animation: grow-in 1s ease-in;
}

.odd {
    transform: translateY(50%);
}

.even p {
    transform: translate(100%, -480%);
}

.odd p {
    text-align: right;
    transform : translate(-100%, -100%)
}

.line-even {
    transform: translate(172px, -85px) rotate(-40deg);
}

.line-odd {
    transform: translate(6px, -27px) rotate(-40deg);
}

.custom-border-box::before {
    top: 0;
    left: 0;
    border-width: 1px 0 0 1px; 
}

.custom-border-box::after {
    bottom: 0;
    right: 0;
    border-width: 0 1px 1px 0; 
}

.container-photo-landing {
    max-width: 550px;
    max-height: 100%;
    height: fit-content;
}

.photo-landing {
    filter: grayscale(100%);
    transition: filter 0.2s ease-in-out;
}

.photo-landing:hover {
    filter: grayscale(0);
}

#tsparticles {
    width: 100%;
    height: 100vh;
    
}

/* Projects */ 

#section-projects {
    display: flex;
}

/* Profile */ 

.drop-shadow-light {
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
}
.drop-shadow-light.shadow-active {
    filter: drop-shadow(2px 6px 3px rgba(0, 0, 0, 0.5));
    transition: filter 0.8s ease-in-out 3s;
}

.drop-shadow-dark {
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
}
.drop-shadow-dark.shadow-active {
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 1));
    transition: filter 0.9s ease-in-out 3s;
}

@keyframes draw-svg {
    to {
        stroke-dashoffset: 0;
    }
}

.svg-path {
    stroke-dashoffset: 2000;
    animation: draw-svg 2.5s ease-out forwards;
}

.drop-shadow-light-image {
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
    transition: filter 0.6s ease-in-out;
}
.drop-shadow-light-image.shadow-active {
    filter: drop-shadow(2px 4px 3px rgba(0, 0, 0, 0.5));
    transition: filter 0.3s ease-in-out;
}

.drop-shadow-dark-image {
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
    transition: filter 0.6s ease-in-out;
}
.drop-shadow-dark-image.shadow-active {
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 1));
    transition: filter 0.3s ease-in-out;
}

@media (max-width: 1145px) {
    .profile, .hard-skills {
        margin-bottom: 30px;
    }

    .middle{
        order: 1;
        width: 100%;
        flex-direction: row;
        justify-content: space-evenly;
        margin-bottom: 100px;
    }
}

@media (max-width: 794px) {
    .middle {
        order: 0;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        margin-bottom: 30px;
    }
    .hard-skills {
        margin-bottom: 60px
    }
}

@media (max-width: 450px) {
    .content-profile h2, .content-certif h2, .content-soft h2, .content-hard h2 {
        font-size: 22px;
    }
    .text-content-profile {
        font-size: 14px;
    }
    .content-profile {
        gap:30px
    }
    .container-photo-profile {
        height: 120px;
    }
    .frame-photo-profile {
        width: 120px;
        height: 120px;
    }
    .photo-profile {
        width: 100px;
        height: 100px;
    }
    .content-certif {
        gap:5px;
    }
    .content-certif div {
        gap:5px
    }
    .content-soft {
        gap: 10px
    }
    .beehive-sm {
        width: 13px;
    }
}

/* Contact */ 

.contact-sumbit {
    font-family:'Orbitron', sans-serif;
}

.frame-submit-lt, .frame-submit-dk, .frame-submit-hovered-lt, .frame-submit-dk {
    transition: fill 0.15s ease-in-out, stroke 0.15s ease-in-out;
}
.frame-submit-lt {
    fill: #fafafa;
    stroke: #757780;
}
.frame-submit-dk {
    fill : #0B3847;
    stroke : #F7F3F5;
}
.frame-submit-hovered-lt {
    fill: #E3170A;
    stroke: #E3170A;
}
.frame-submit-hovered-dk {
    fill: #CFD11A;
    stroke: #CFD11A;
}
.input-submit-lt, .input-submit-dk, .input-submit-hovered-lt, .input-submit-hovered-dk {
    transition: color 0.15s ease-in-out
}
.input-submit-lt {
    color: black;
}
.input-submit-dk {
    color : #F7F3F5;
}
.input-submit-hovered-lt {
    color: #fafafa;
}
.input-submit-hovered-dk {
    color: #0B3847;
}

/* --------------------- */
/* -----COMPONENTS------ */
/* --------------------- */

/* Header */ 

.shadow-head-100 {
    transition: box-shadow 0.5s ease-out;
    -webkit-box-shadow: 0px 2px 15px 2px rgba(0, 0, 0, 0.1); 
    box-shadow: 0px 5px 15px 5px rgba(0,0,0,0.1);
}

.shadow-head-0 {
    transition: filter 0.3s ease-out;
    filter: drop-shadow(0px 0px 0px #000000);
}

/* Navbar */

.transition-font-weight {
    font-weight: bold;
}

/* Tablet Menu */ 

.shadow-tablet-0 {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.shadow-tablet-100 {
    box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.5s ease-out 5s;
}

.menu-tablet-on {
    transform: rotate(90deg);
    transition: transform 0.5s ease-in-out;
}

.menu-tablet-off {
    transform: rotate(-90deg);
    transition: transform 0.5s ease-in-out;
}

.menu-tablet-open {
    position: fixed;
    z-index: -1;
    top: 5rem;
    right: 0;
    height: 100px;
    width: 130px;
    background-color: #f5f5f5;
    transform: translateY(0);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-out 0.2s;
}

.menu-tablet-close {
    position: fixed;
    z-index: -1;
    top: 5rem;
    right: 0;
    height: 100px;
    width: 130px;
    background-color: #f5f5f5;
    transform: translateY(-100px);
    transition: transform 0.3s ease-in-out;
}

/* Menu Mobile */

.burg-top, .burg-mid, .burg-down {
    width: 100%; 
    height: 3px;
    border-radius: 5px;
}

.burger-on {
    transform: rotate(45deg) translateY(9px) translateX(5px);
    transform-origin: center center;
    transition: transform 0.3s ease-out;
}

.burger-on + .burg-mid  {
    opacity: 0%;
    transition: opacity 0.1s ease;
}

.burger-on + .burg-down  {
    transform: rotate(-45deg) translateY(-9px) translateX(5px);
    transform-origin:center center;
    transition: transform 0.3s ease-out;
}

.burger-off {
    transform: rotate(0deg) translateX(0px);
    transition: transform 0.3s ease-out;
}

.burger-off + .burg-mid {
    opacity: 1;
    transition: opacity 0.1s ease;
}

.burger-off + .burg-down {
    transform: rotate(0deg) translateX(0px);
    transition: transform 0.3s ease-out;
}

.menu-mobile-open {
    position: fixed;
    z-index: -20;
    top: 3rem;
    right: 0;
    height: 500px;
    width: 100%;
    background-color: #f5f5f5;
}

.menu-mobile-close {
    position: fixed;
    z-index: -20;
    top: 3rem;
    right: 0;
    left: 0;
    height: 500px;
    width: 100%;
    background-color: #f5f5f5;
}


/* IconScroll */ 

@keyframes moveY {
    to {
      transform: translateY(10px);
    }
  }

.arrow-scroll {
    animation: moveY 1.5s ease-in-out infinite alternate;
}

/* SingleProject */

.details-projects , .images-projects {
    opacity: 0;
    animation: fade-in 0.5s ease-in forwards;
}

.details-projects {
    animation-delay: 200ms;
}

.images-projects {
    animation-delay: 400ms;
}

/* ImagesProjects */

.container-image:hover .more-left, .container-image:hover .more-right , .container-image:hover .see-more button {
    border-color: #E3170A;
    color: #E3170A;
}

.container-image:hover .filter-more {
    background-color: rgba(0, 0, 0, 0);
}

/* ModalGallery */

@keyframes moveX {
    from {
        transform: translateX(-5px)
    }
    to {
      transform: translateX(5px);
    }
  }

.finger-swipe {
    animation: moveX 1.5s ease-in-out infinite alternate
}

.fade-in-gallery {
    opacity: 0;
    animation: fade-in 0.6s ease-out forwards;
}

/* Rates */

.beehive-sm {
    transform: scale(1);
    transition: transform 0.7s ease-out;
}

.beehive-sm:hover {
    transform: scale(1.25);
    transition: transform 0.2s ease-out;
}

/* Collapse */

.collapse-closed {
    max-height: 50px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}

.collapse-open {
    max-height: 300px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}

.content-collapse-closed {
    transform: translateY(-100%);
    transition: transform 0.25s ease-in-out;
}

.content-collapse-open {
    transform: translateY(0);
    transition: transform 0.25s ease-out;
}

.arrow-collapse-open {
    transform: rotate(540deg);
    transition: transform 0.45s ease-in;
}

.arrow-collapse-closed {
    transform: rotate(0deg);
    transition: transform 0.45s ease-out;
}


/* --------------------- */
/* ----Media-Queries---- */
/* --------------------- */

@media (min-width: 1024px) {
    .container-beehive:hover .logo-techno {
        transform: scale(1.1);
        filter: grayscale(0);
    }

}

@media (max-width: 1024px)  {
    html {
        scroll-snap-type: none;
    }

    h1 {
        font-size: 2.25rem;
    }
    h2 {
        font-size: 1.625rem;
    }

    .photo-landing {
        filter: grayscale(0);
    }
}

@media (max-width: 768px) {
    html {
        scroll-snap-type: none;
    }

    /* Technologies */

    .even {
        transform: translateX(-20%);
    }
    
    .odd {
        transform: translate(20%, 0%);
    }

    .even p {
        text-align: center;
        transform: translate(0%);
    }

    .odd p {
        text-align: center;
        transform : translate(0%)
    }

    .logo-techno-dark-unclicked {
        filter: brightness(1000%);
    }
}

@media (max-width: 450px) {
    html {
        scroll-snap-type: none;
    }
}